void function () {
    'use strict';

    $(document).on('click', '.btn[data-confirm]', function (evt) {
        let form, button = $(this),
            key = button.attr('data-confirm');
        if (config.messages[key]) {
            evt.preventDefault();
            evt.stopPropagation();
            Biceps.confirm(config.messages[key], function () {
                if (button.attr('href')) {
                    window.location = button.attr('href');
                } else {
                    form = button.parents('form');
                    if (form.length > 0) {
                        form.submit();
                    }
                }
                button.trigger('confirmation-accepted');
            }, function () {
                button.trigger('confirmation-refused');
            });
        }
    });
    $(function () {
        // Load flash messages
        toastr.options.positionClass = "toast-top-center";
        $.each(Biceps.config.notifications, function () {
            switch (this.type) {
                case 'success':
                    toastr.success(this.message);
                    break;
                case 'error':
                    toastr.error(this.message);
                    break;
                case 'notice':
                    toastr.info(this.message);
                    break;
                case 'warning':
                    toastr.warning(this.message);
                    break;
            }
        });
    });
    $(function () {
        let app = $('#app'),
            toggleOpen = $('#sidebar-collapse-btn'),
            toggleClose = $('#sidebar-overlay');

        toggleOpen.on('click touchstart', function (evt) {
            evt.preventDefault();
            evt.stopPropagation();
            app.addClass('sidebar-open');
        });
        toggleClose.on('click touchstart', function (evt) {
            evt.preventDefault();
            evt.stopPropagation();
            app.removeClass('sidebar-open');
        });
    })
}();