let Biceps = {};

Biceps.config = {};
Biceps.extend = function (options) {
    Biceps.config = $.extend({}, Biceps.config, options || {});
};

Biceps.uniqueIndex = 0;
Biceps.uniqueId = function () {
    Biceps.uniqueIndex = Biceps.uniqueIndex + 1 || 0;
    return 'biceps-uid-' + Biceps.uniqueIndex;
};

/**
 * Show a dialog displaying the 'msg' message.
 */
Biceps.alert = function (msg) {
    let $modal = $('#alert-modal');

    $modal.find('.modal-message').html(msg);
    $modal.modal({
        backdrop: 'static',
        keyboard: false
    });
};

/**
 * Show a confirmation dialog displaying the 'msg' message, then calls yesCallback if YES was pressed
 * or noCallback if it was NO.
 *
 * Will not work in a re-entrant way. I.e. you cannot call Biceps.confirm inside the yesCallback or the noCallback.
 */

Biceps.confirm = function (msg, yesCallback, noCallback, disableKeyboard) {
    let $modal = $('#confirm-modal'),
        escEnabled = typeof disableKeyboard !== 'undefined' ? !disableKeyboard : false;

    $modal.on('click.modal', '.btn-yes', function () {
        $modal.off('click.modal');
        $modal.modal('hide');
        yesCallback && yesCallback();
    });

    $modal.on('click.modal', '.btn-no', function () {
        $modal.off('click.modal');
        $modal.modal('hide');
        noCallback && noCallback();
    });

    $modal.find('.modal-message').html(msg);
    $modal.modal({
        backdrop: 'static',
        keyboard: escEnabled
    });
};

Biceps.dateFormat = function (date, withHours, defaultValue) {
    if (!date) {
        return defaultValue ? defaultValue : '';
    }
    let pad = function (s) {
            return ('' + s).padStart(2, '0');
        },
        dd = date.split(/[^0-9]/),
        d = new Date (dd[0], dd[1]-1, dd[2], dd[3], dd[4], dd[5]),
        formatedDate = pad(d.getDate()) + '.' + pad(d.getMonth() + 1) + '.' + d.getFullYear();
    if (withHours) {
        return formatedDate + ' ' + pad(d.getHours()) + ':' + pad(d.getMinutes());
    }

    return formatedDate;
};

/**
 * Try to find an href attribute on the 'target' element or it's parent.
 */
Biceps.graphicLinkHref = function (target) {
    let $target = $(target);
    if (!$target.attr('href')) {
        $target = $target.parent();
    }
    if ($target.attr('href')) {
        return $target.attr('href');
    }
    return false;
};

try {
    module.exports = Biceps;
} catch (e) {

}