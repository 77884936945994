
const $ = require('jquery');
global["$"] = global["jQuery"] = $;

require('bootstrap');

const toastr = require('toastr/build/toastr.min.js');
global["toastr"] = toastr;

const Biceps = require('../../../../CoreBundle/Resources/javascripts/Biceps');
global["Biceps"] = Biceps;
try {
    Biceps.extend(config);
}catch(e){
    Biceps.extend({});
}

require('../../theme/css/vendor.css');
require('toastr/build/toastr.min.css');
require('../../theme/css/app.css');
require('../../styles/registration.scss');
require('../../styles/app.scss');
require('../../styles/skins/skin-2022.scss');

require('../../javascripts/App');